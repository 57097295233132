import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import { useRef, useState, useEffect } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useMicrocopy } from "@/hooks/useMicrocopy";
import { ArrowRightIcon } from "../icons";

/**
 * This script allows cookieinformation to insert a "Cookies Declaration"
 * It's a table displaying all the cookies used on the site.
 * It is injected through richTextOptions
 * It also adds a button to update privacy settings.
 */
export function CookiesList() {
    const { getMicrocopy } = useMicrocopy();
    const cookiePolicyRef = useRef();
    const [showPrivacyControls, setShowPrivacyControls] = useState(false);

    useIsomorphicLayoutEffect(() => {
        const script = document.createElement("script");
        script.setAttribute(
            "src",
            "https://policy.app.cookieinformation.com/cid.js"
        );
        script.setAttribute("id", "CookiePolicy");
        script.setAttribute("type", "text/javascript");
        script.dataset.culture = "DA";
        cookiePolicyRef.current.appendChild(script);
    }, []);

    useEffect(() => {
        if (typeof window?.CookieConsent?.show === "function") {
            setShowPrivacyControls(true);
        }
    }, []);

    return (
        <>
            {showPrivacyControls && (
                <Box display="flex" flexDirection="row" alignItems="baseline">
                    <Button
                        onClick={() => window.CookieConsent.show()}
                        variant="anchorLink"
                        fontSize="h4"
                        pb={0}
                        mt={4}
                        mb={8}
                        pr="2"
                    >
                        {getMicrocopy("global", "cookies.opdater", {
                            fallback: "Opdater dine cookieindstillinger",
                        })}
                    </Button>
                    <ArrowRightIcon fill="#00AEFF" />
                </Box>
            )}
            <div ref={cookiePolicyRef} />
        </>
    );
}
