import { useEffect, useState } from "react";

/**
 * Zustand Static Site Generation Component Wrapper
 *
 * Using Client-side persisted State, on a Static Site Generated page (build time rendered pages with getStaticPaths + getStaticProps)
 * may create whats called "hydration mismatch errors" in React.
 *
 * This occurs when:
 *  - Next.js pre-renders a page during build time
 *  - Next.js sends the pre-build page to the client
 *  - The client has some client-side values, i.e. zustand state stored in localStorage (persist)
 *  - These values are used on the pre-build page, but now change some DOM areas
 *  - The pre-rendered DOM and client-side DOM are mismatching
 *
 * Above mismatch is what's known as "hydration mismatch error".
 *
 * To solve this, we have to render same DOM placeholder during build time, and client side until we have the correct values.
 * Here, a combination of useEffect and useState solves this, as useEffect is only ran after the component has been mounted,
 * and thereby fully allowing Client Side rendering without hydration errors.
 *
 * The "ZustandSSG" is a wrapper for Components, where they or their nested child-components use
 * Zustand's client-side persisted state. It returns during both build-time and initial client side rendering "null"
 * and thereby avoiding mismatching DOM. First when the component has been mounted, just after the initial render, is
 * the DOM updated.
 *
 * Note: This is only an issue for pages with Static Site Generation using Client-side persisted State,
 * i.e. pages that use Zustand Store, getStaticPaths and getStaticProps
 *
 * @example
 *
 *      function SSGPage(){
 *          const [bears] = useBearStore(state=>[state.bears])
 *
 *          return(
 *              <div>
 *                  <ComponentsThatAreStaticAndDontNeedZustand/>
 *
 *                  <ZustandSSG>
 *                      <ComponentsThatNeedZustand bears={bears}>
 *                  </ZustandSSG>
 *              </div>
 *          )
 *      }
 *
 * @see https://www.joshwcomeau.com/react/the-perils-of-rehydration/#schrodingers-user
 * @see https://github.com/vercel/next.js/discussions/17443#discussioncomment-87097
 * @see https://github.com/pmndrs/zustand/issues/324
 * @see https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md#qa
 */
export function ZustandSSG({ children }) {
    const [hasMounted, setHasMounted] = useState(false);
    useEffect(() => {
        setHasMounted(true);
    }, []);
    if (!hasMounted) {
        return null;
    }
    return <>{children}</>;
}
