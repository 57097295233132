import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";
import { textSpecs } from "@/theme/foundations/typography";

export function ProductCardPrice({ price, priceBefore, includePriceUnit, includeCustomPriceUnit, priceBeforeColor = "inherit", sx }) {
    const priceUnit = ",-";

    return (
        <Text
            sx={{
                position: "relative",
                display: "inline-block",
                fontSize: textSpecs.h2.fontSize.xl,
                lineHeight: "51px",
                fontWeight: "black",
                textTransform: "uppercase",
                marginBottom: 0,
                ...sx,
            }}
        >
            {price}

            {includeCustomPriceUnit && <Text fontSize={"3rem"} as={"span"}>{priceUnit}</Text>}

            {includePriceUnit && <Text as={"span"}>{priceUnit}</Text>}

            {!!priceBefore && (
                <>
                    <Text
                        as="span"
                        variant="handWritten"
                        sx={{
                            color: priceBeforeColor,
                            fontSize: "h3",
                            display: "flex",
                            position: "absolute",
                            top: 0,
                            right: "100%",
                            marginRight: 2,
                            transform: "translateY(-25%) rotate(-10deg)",
                        }}
                    >
                        <Text
                            as="span"
                            sx={{
                                display: "inline-block",
                                position: "relative",
                                _after: {
                                    content: "''",
                                    height: "2px",
                                    width: "150%",
                                    backgroundColor: "currentColor",
                                    position: "absolute",
                                    top: "55%",
                                    left: "50%",
                                    transform:
                                        "translateX(-50%) rotate(-12deg)",
                                },
                            }}
                        >
                            {priceBefore}
                        </Text>
                        {priceUnit}
                    </Text>
                </>
            )}
        </Text>
    );
}

ProductCardPrice.propTypes = {
    price: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
    priceBefore: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    includePriceUnit: PropTypes.bool,
    flexible: PropTypes.bool,
};

ProductCardPrice.defaultProps = {
    includePriceUnit: true,
    variant: "default",
};
