/**
 * Push e-commerce tracking data to DataLayer
 * Note: Item object must match the ones being set in checkout..
 */
export const handleProductClickTracking = (product) => {
    // Set price as either regular or campaign price
    const price = product.campaign?.fields
        ? product.campaign?.fields.campaignPrice
        : product.price;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [
                {
                    item_id: product.optionKey,
                    item_name: product.displayName,
                    price,
                    discount: product.campaign?.fields
                        ? product.price - product.campaign.fields.campaignPrice
                        : 0, // calculate discount based on price and campaign price
                    currency: "DKK",
                    quantity: 1,
                    item_variant: product?.campaign?.fields?.code || null,
                    item_brand: "Flexii",
                    item_category: product.itemCategory || "Voice",
                    item_category2: product.voiceHours, // Voice hours
                    item_category3: `${product.data} gb`, // GB
                },
            ],
        },
    });
};
