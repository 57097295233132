import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Box, Link, chakra, Heading, Button } from "@chakra-ui/react";
import NextLink from "next/link";
import Image from "next/image";
import { ArrowRightIcon } from "../icons";
import { ProductCard } from "./ProductCard";
import { CookiesList } from "./CookiesList";

/**
 * Shared RichText Configuration.
 * The Richtext component can still be customized from within Contentful to configure which components to allow, per content_model.
 */
export const richTextOptions = {
    renderNode: {
        // Lists
        [BLOCKS.OL_LIST]: (node, children) => (
            <chakra.ol
                sx={{
                    padding: 0,
                    listStylePosition: "inside",
                }}
            >
                {children}
            </chakra.ol>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
            <chakra.ul
                sx={{
                    padding: 0,
                    listStyle: "none",
                    ".custom-li-bullet": {
                        marginRight: 3,
                        _before: {
                            content: `"\\2022"`,
                        },
                    },
                }}
            >
                {children}
            </chakra.ul>
        ),
        [BLOCKS.LIST_ITEM]: (node, children) => {
            // Note: Custom bullet is injected as unicode character with CSS from [BLOCKS.UL-LIST]
            // as we can't see if the list item is rendered from <ul> or <ol>.

            // Set fontsize on custom bullet to match content size
            let listFontSize = "";
            switch (node.content[0].nodeType) {
                case BLOCKS.HEADING_1:
                    listFontSize = "h1";
                    break;
                case BLOCKS.HEADING_2:
                    listFontSize = "h2";
                    break;
                case BLOCKS.HEADING_3:
                    listFontSize = "h3";
                    break;
                case BLOCKS.HEADING_4:
                    listFontSize = "h4";
                    break;
                case BLOCKS.HEADING_5:
                    listFontSize = "h5";
                    break;
                case BLOCKS.HEADING_6:
                    listFontSize = "h6";
                    break;
                default:
                    listFontSize = "";
                    break;
            }

            return (
                <li>
                    <Box
                        sx={{
                            display: "inline-flex",
                            alignItems: "baseline",
                        }}
                    >
                        <Box
                            as="span"
                            className="custom-li-bullet"
                            textStyle={listFontSize}
                        />
                        {children}
                    </Box>
                </li>
            );
        },

        // Tables
        [BLOCKS.TABLE]: (node, children) => (
            <Box
                className="contentful-table-wrapper"
                sx={{ overflow: "auto", maxWidth: "full" }}
            >
                <Box className="contentful-table">{children}</Box>
            </Box>
        ),
        [BLOCKS.TABLE_ROW]: (node, children) => (
            <Box
                className="contentful-table-row"
                sx={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gridAutoColumns: {
                        base: "minmax(200px, 1fr)",
                        md: "1fr",
                    },
                }}
            >
                {children}
            </Box>
        ),
        [BLOCKS.TABLE_CELL]: (node, children) => (
            <Box className="contentful-table-cell">{children}</Box>
        ),
        [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
            <Box className="contentful-table-header-cell">{children}</Box>
        ),

        // Embedded modules
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            const componentData = node.data.target.fields;
            if (!componentData) return null;

            const componentType = node.data.target.sys.contentType.sys.id;

            switch (componentType) {
                case "headline":
                    return (
                        <Heading
                            as={componentData.semanticHtmlTag}
                            variant={componentData.textStyle}
                        >
                            {componentData.headlineText}
                        </Heading>
                    );
                case "iFrame":
                    return (
                        <iframe
                            src={componentData.iFrameUrl}
                            width={componentData.width || "100%"}
                            height={componentData.height}
                        />
                    );
                case "subscription":
                    return (
                        <ProductCard
                            {...componentData}
                            isActive
                            sx={{
                                display: "inline-block",
                                marginX: 4,
                                marginY: 8,
                            }}
                            context="richText"
                        />
                    );
                case "navigationItem":
                    return (
                        <Button
                            as={NextLink}
                            href={componentData.linkUrl}
                            variant={componentData.variant || "mingle"}
                            rightIcon={
                                [
                                    undefined,
                                    "mingle",
                                    "mingle--white",
                                    "linkArrow",
                                ].includes(componentData.variant) ? (
                                    <ArrowRightIcon />
                                ) : null
                            }
                            target={
                                componentData.openInNewWindow
                                    ? "_blank"
                                    : undefined
                            }
                        >
                            {componentData.linkLabel}
                        </Button>
                    );
                case "hyperlinkImage":
                    const { url, details } = componentData.image.fields.file;
                    return (
                        <NextLink
                            href={componentData.hyperlink}
                            sx={{
                                _hover: {
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <Image
                                src={`https:${url}`}
                                width={details.image.width}
                                height={details.image.height}
                                alt={
                                    componentData.image.fields
                                        ?.description ||
                                    componentData.image.fields?.title
                                    || ""
                                }
                            />
                        </NextLink>
                    );
                default:
                    return <>Custom Component</>;
            }
        },

        // Embedded assets
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const componentData = node.data.target.fields?.file;
            if (!componentData) return null;

            const { url, details } = componentData;

            return (
                <Image
                    src={`https:${url}`}
                    width={details.image.width}
                    height={details.image.height}
                    alt={componentData.description || ""}
                />
            );
        },
        [INLINES.HYPERLINK]: (node, children) => {
            if (node.data.uri === "[CookiesList]") {
                // If link target matches then
                // inject script rendering Cookie Declaration
                return <CookiesList />;
            }
            return (
                <Link href={node.data.uri} as={NextLink}>{children}</Link>
            );
        },
        // Links to assets
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
            const componentData = node.data.target.fields?.file;
            if (!componentData) return null;

            return (
                <Link
                    href={componentData.url}
                    target="_blank"
                    sx={{
                        fontWeight:
                            node.content[0].marks[0]?.type === "bold"
                                ? "black"
                                : null,
                    }}
                >
                    {children}
                </Link>
            );
        },
    },

    // Add linebreaks
    renderText: (text) => {
        return text
            .replace(/\u2028/g, "") // Remove line separators (L SEP symbols)
            .split("\n")
            .reduce((children, textSegment, index) => {
                // Replace carriage return (shift+enter) with <br/>
                return [
                    ...children,
                    index > 0 && <br key={index} />,
                    textSegment,
                ]; // .trim()
            }, []);
    },
};
