export const initialCheckoutSession = {
    user: {
        accountId: null, // Only for existing users
        firstName: null, // string
        lastName: null, // string
        gender: null, // string "M" | "W"
        streetName: null, // string
        houseNumber: null, // string
        floor: null, // string
        buildingNumber: null, // string
        sideNumber: null, // string
        zip: null, // string
        city: null, // string
        country: null, // string
        email: null, // string
        phone: null, // string
        personalId: null, // string - CPR Number without dashes/spaces
        customerNumber: null, // string
    },

    onlyUpdateCreditCard: false,
    productKey: null,
    subscription: null,
    secretNumber: true,
    balanceControl: false,

    /**
     * OPTION A - New number request contains the following schema
     */
    anumber: {
        anumber: null, // string - The preferred available phone number
        reservationToken: null, // string - The reservation token of the preferred available phone number
    },

    /**
     * OPTION B - Keep older number and transfer request has following schema
     */
    anumberImportRequest: {
        phoneNumber: null, // string - The phone number to transfer to Flexii
    },

    clientSidePaymentSuccess: false, // Adyen's client side success response. Only used for UI/UX purpose, but not the actual safe success response from Adyen -> Webhook
    advertisingConsent: false, // Track if consent is given for marketing
    analytics: {} // UTM and other query parameters for analytics
};

/**
 * Initial state
 * "produce" is required to mutate nested properties
 * @see https://github.com/pmndrs/zustand/wiki/Updating-nested-state-object-values
 */
export const checkout = (set) => ({
    /**
     * Checkout Session
     */
    checkoutSession: initialCheckoutSession,
    setCheckoutSession: (payload) => {
        set((state) => {
            state.checkout.checkoutSession = payload;
        });
    },
    resetCheckoutSession: () => {
        set((state) => {
            state.checkout.checkoutSession = initialCheckoutSession;
        });
    },
});
