import PropTypes from "prop-types";
import { Box, Divider } from "@chakra-ui/react";

export function ProductCardSection({
    hasBottomBorder,
    canFlex,
    children,
}) {
    return (
        <Box className="productcard-section" sx={{
            paddingX: 3,
            flex: canFlex && "1 1 auto",
        }}>
            <Box sx={{
                paddingY: 5,
             }}>
                {children}
            </Box>
            <Box sx={{ paddingX: 7 }}>
                {!!hasBottomBorder && <Divider variant="productCard"/>}
            </Box>
        </Box>
    );
}

ProductCardSection.propTypes = {
    children: PropTypes.node,
    hasBottomBorder: PropTypes.bool,
    canFlex: PropTypes.bool,
};

ProductCardSection.defaultProps = {
    hasBottomBorder: false,
    canFlex: false,
}
